.default {
    width: calc(100% - 190px);
    height: 100%;
    display: flex;
    margin-left: 190px;
    &-content {
        transition: all ease 0.5s;
    }
}

@media (max-width: 1201px) {
    .default {
        width: 100%;
        margin-left: 0;
    }

    .sidebar-show .default {
        overflow: hidden;
    }
    .sidebar-show .default-content {
        margin-left: 250px;
    }
    .sidebar-show .default-backdrop {
        position: fixed;
        top: 70px;
        left: 0;
        width: 100%;
        height: calc(100% - 70px);
        z-index: 9997;
    }
}