.form-control,
.form-select {
    font-size: 14px;
    border-radius: 8px;
    padding: 8px 12px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &::placeholder {
        color: #a6a8ac;
    }
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: rgb(185, 28, 28);
    background-image: none;
}

.invalid-feedback {
    color: rgb(185, 28, 28);
}

.input-group {
    border-radius: 8px;
}

.input-group-text {
    border-radius: 8px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    i {
        color: rgb(168, 181, 200);
    }
}

.form-control:focus + .input-group-text {
    border-color: #86b7fe;
}

.form-control.disabled {
    background-color: var(--bs-secondary-bg);
}