.btn {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 20px;
    border-radius: 8px;
    &-sm {
        font-size: 12px;
        padding: 4px 20px;
        border-radius: 6px;
    }
    &-primary {
        background-color: #1f3a8a;
        border-color: #1f3a8a;
        color: #fff;
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active
        &:active:focus,
        &:visited,
        &:target,
        &.disabled,
        &:disabled {
            background-color: #354e96;
            border-color: #354e96;
            color: #fff;
        }
    }
    &-secondary {
        background-color: #eaeff5;
        border-color: #eaeff5;
        color: #64748b;
        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active
        &:active:focus,
        &:visited,
        &:target,
        &.disabled,
        &:disabled {
            background-color: #f3f5f8;
            border-color: #f3f5f8;
            color: #64748b;
        }
        &-outline {
            border-color: #eaeff5;
            color: #64748b;
        }
    }
    &-outline {
        &-primary {
            border-color: #1f3a8a;
            color: #1f3a8a;
            &:hover,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:active
            &:active:focus,
            &:visited,
            &:target,
            &.disabled,
            &:disabled {
                background-color: #354e96;
                border-color: #354e96;
                color: #fff;
            }
        }
    }
}