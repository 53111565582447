.card {
    border: none;
    box-shadow: 0 10px 30px 0 rgba(var(--bs-primary-dark-rgb), 0.1)!important;
    border-radius: var(--bs-card-border-radius);
    &-header {
        background-color: unset;
        padding: 20px;
        font-weight: 600;
        font-size: 14px;
    }
    &-body {
        padding: 20px;
    }
}