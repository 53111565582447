.react-datepicker-wrapper {
    width: 100%;
    display: block;
}

@media print {
    .reservations:not(.modal-open) {
        overflow: auto !important;
        height: auto !important;
        background-color: unset;
        #root {
            display: unset;
        }
        .sidebar {
            display: none;
        }
        .default {
            width: 100%;
            margin: 0;
        }
        .navbar {
            display: none;
        }
        .container-fluid {
            background: unset !important;
            padding: 0 !important;
            h2 {
                display: none;
            }
            h2 + .row,
            h2 + .row + .row {
                display: none;
            }
        }
        table  th,
        table  td {
            box-shadow: unset !important;
            padding: 2px !important;
        }
        table  th:first-child,
        table  td:first-child,
        table  th:last-child,
        table  td:last-child {
            display: none;
        }
        .table-clean th,
        .table-clean td {
            font-size: 10px;
        }
        
        .table-responsive + div {
            display: none;
        }
        .form-select {
            padding: 0;
            border: 0;
            font-size: 10px;
            background: unset;
        }
    }
}