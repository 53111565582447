:root {
    --bs-body-color: #334155;
    --bs-body-font-size: 0.8rem;
    --bs-primary-rgb: 31, 58, 138;
    --bs-primary-dark-rgb: 25, 50, 117;
    --bs-light-rgb: 241, 245, 249;
    --bs-link-color: #1e40af;
    --bs-link-color-rgb: (30, 64, 175);
    --bs-link-decoration: underline;
    --bs-link-hover-color: #244bce;
    --bs-link-hover-color-rgb: 36, 75, 206;
    --bs-border-radius: 15px;
    --bs-success-rgb: 54, 158, 148;
}

:any-link {
//   color: rgb(30, 58, 138);
}

body {
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

#root {
    height: 100%;
}

a {
    text-decoration: none !important;
}

.bg-primary-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-dark-rgb),var(--bs-bg-opacity))!important;
}

.fw-600 {
    font-weight: 600 !important;
}

.pre {
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    a {
        color: var(--bs-link-color);
    }
}

@media print {
    body {
        overflow: auto !important;
        height: auto !important;
    }
    #root {
        display: none;
    }
    .modal {
        position: unset !important;
        overflow: auto !important;
        height: auto !important;
        --bs-modal-box-shadow: unset;
        --bs-modal-bg: #fff;
    }
    .modal-lg, .modal-xl {
        position: relative !important;
        height: auto !important;
    }
    .modal-backdrop {
        display: none !important;
    }
    .modal-content {
        border: none;
    }
    .modal-lg, .modal-xl {
        --bs-modal-width: 100%;
        --bs-modal-height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        margin: 0 !important;
    }
    .btn-close {
        display: none;
    }
}

.row-white {
    background-color: white;
  }
  
  /* Estilo CSS para a cor cinza */
  .row-gray {
    background-color: gray;
  }