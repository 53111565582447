.pagination {
    .page-link {
        background: unset !important;
        border: unset !important;
        font-weight: 500;
        color: rgb(30, 41, 59);
        font-size: 14px;
        padding: 8px 16px;
        margin: 0 10px;
        border-radius: 8px;
        box-shadow: unset !important;
    }
    .active>.page-link,
    .page-link.active {
        background: #fff !important;
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.043) !important;
    }
}