.table-clean {
    border-collapse: separate;
    border-spacing: 0 10px;
    th {
        background: unset;
        font-weight: 600;
        color: rgb(51, 65, 85);
        font-size: 12px;
        padding: 10px 10px;
        border: none;
    }
    td {
        color: rgb(51, 65, 85);
        font-size: 12px;
        padding: 10px 10px;
        vertical-align: middle;
        border: none;
        box-shadow: 20px 3px 20px 0px rgba(0, 0, 0, 0.043);
        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &:last-child {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}