.login {
    &-circle-1 {
        width: 46%;
        height: 150%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        background-color: #d2d7e9;
        position: absolute;
        top: -41%;
        left: 0;
    }
    &-circle-2 {
        width: 45%;
        height: 140%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        background-color: #1f3a8a;
        position: absolute;
        top: -30%;
        left: 0;
    }
}

@media (max-width: 1201px) {
    .login {
        &-text {
            display: none;
        }
    }
}