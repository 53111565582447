.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 170px;
    z-index: 9998;
    transition: all ease 0.5s;
    &-header {
        position: relative;
        z-index: 2;
    }
    &-navlink {
        position: relative;
    }
    &-navlink.active {
        background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
        color: #334155 !important;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        // &:after {
        //     content: "";
        //     display: block;
        //     background-color: red;
        //     width: 50px;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        // }
    }
    &-navlink.active &-navlink-border-1,
    &-navlink.active &-navlink-border-2 {
        display: block;
        width: 30px;
        height: 30px;
        position: absolute;
        top: -30px;
        right: 0;
        &:before {
            content: "";
            display: block;
            background-color: rgba(var(--bs-primary-dark-rgb),var(--bs-bg-opacity))!important;
            width: 100%;
            height: 100%;
            border-bottom-right-radius: 30px;
            position: absolute;
            top: 0;
            left: 0;
        }
        &:after {
            content: "";
            display: block;
            background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
            width: 100%;
            height: 100%;
        }
    }
    &-navlink.active &-navlink-border-2 {
        top: unset;
        bottom: -30px;
        &:before {
            border-bottom-right-radius: 0;
            border-top-right-radius: 30px;
        }
    }
}

@media (max-width: 1201px) {
    .sidebar {
        min-width: 250px;
        left: -250px;
        &-show .sidebar {
            left: 0;
        }
    }
}